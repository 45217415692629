import React, { useContext, ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";

export interface User {
  name: string;
  email: string;
  sharing: boolean;
  has_global_access: boolean;
  has_email_scope: boolean;
}

interface AppProviderProps {
  user: User | null;
}

export const AppContext = React.createContext<null | AppProviderProps>(null);

interface AppProviderType {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderType) => {
  const { isLoading, error, data } = useQuery<User>({
    queryKey: ["me"],
    queryFn: () =>
      fetch("/me", {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return "error";
  }

  if (!data) {
    return null;
  }

  return <AppContext.Provider value={{ user: data }}>{children}</AppContext.Provider>;
};

export function useApp() {
  const value = useContext(AppContext);
  if (value === null) {
    throw new Error("useApp must be wrapped in a <AppProvider />");
  }
  return value;
}
